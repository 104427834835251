<template>
  <div ref="map" id="map" style="height: 100%; width: 100%"></div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import io from "socket.io-client";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
export default defineComponent({
  name: "Sapa Rakyat Door to Door",
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  mounted() {
    const socket = io(ApiService.vueInstance.axios.defaults.baseURL);
    socket.emit("markerPersons");
    this.map = L.map("map", {
      zoomControl: true,
      zoom: 1,
      zoomAnimation: false,
      fadeAnimation: true,
      markerZoomAnimation: true,
    }).setView([-7.116841, 112.470853], 10);

    // Add tile layer (you can choose a different one)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      this.map
    );

    // Fetch initial markers from your server
    // socket.on("markers", (data) => {
    //   this.addMarkers(data);
    // });

    // Listen for new markers in real-time
    socket.on("marker", (marker) => {
      this.addMarker(marker);
    });
  },
  methods: {
    addMarkers(markers) {
      markers.forEach((marker) => {
        this.addMarker(marker);
      });
    },
    addMarker(marker) {
      const {
        id,
        created_name,
        rt,
        rw,
        profile,
        address,
        territory_name,
        kecamatan_name,
        kabupaten_name,
        latitude,
        longitude,
      } = marker;

      // Create a custom icon for the marker
      const path = window.location.origin + "/";
      const icon = path + "other/png/flag.png";
      const customIcon = L.icon({
        iconUrl: icon, // Replace with the path to your custom icon image
        iconSize: [24, 24], // Size of the icon
        iconAnchor: [16, 32], // Anchor point of the icon
      });

      // popup content
      const popupContent = `<div class="card"><div class="card-body text-start p-0"><div class="fs-5 fw-bolder">${name}</div><div class="">${address} RT.${rt}/RW.${rw}, ${territory_name}, ${kecamatan_name} - ${kabupaten_name}</div><div class="fw-bold mb-3">oleh : ${created_name}</div><img class="img-fluid" src="${profile}" alt="foto kegiatan" style="max-width:150px"><br><a class="btn btn-primary btn-sm btn-block mt-2" href="/person-map/detail/${id}" target="_blank">Lihat selengkapnya</a></div></div>`;

      const customMarker = L.marker([latitude, longitude], {
        icon: customIcon,
      })
        .bindPopup(popupContent)
        .addTo(this.map);
      this.markers.push(customMarker);
    },
  },
  setup() {
    setCurrentPageTitle("Sapa Rakyat Door to Door");
  },
});
</script>
